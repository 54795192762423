.Author {
    margin: 24px;
    display: inline-block;
    width: 275px;
    height: auto;
    font-size: 1.15rem;
    position: relative;
    background-color: white;
    border-radius: 6px;
    color: rgb(59,59,59);
    padding: 0 4px;
    box-sizing: border-box;
    box-shadow: 4px 4px rgba(204, 204, 204, 0.5);
}

.Author a {
    color: rgb(59,59,59);
}

.Author a:hover {
    color: #555;
}
