.RobotBuilder div {
}

.RobotBuilder h2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: inline-block;
    background: inherit;
    box-sizing: border-box;
}
