.Input {
}

.Input input {
    position: center;
    width: 275px;
    height: 38px;
    font-size: 1.25rem;
    border-radius: 6px;
    padding: 2px 4px;
    border: 1px solid #eee;
    box-sizing: border-box;
    box-shadow: 4px 4px rgba(204, 204, 204, 0.5);
}

.Input input:focus {
}
